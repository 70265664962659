export default function SidebarIcon() {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth="3"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="3" width="20" height="19" rx="2" ry="2" />
      <line x1="10" y1="4" x2="10" y2="20" />
    </svg>
  )
}
