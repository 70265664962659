const config = {
  appName: 'Rockite',
  persistencePrefix: 'RockitePrefs',
  websiteLink: 'https://rockite.pages.dev',
  issueTrackerLink: 'https://github.com/psyirius/rockite/issues',
  // TODO: Update this link once the extension is published
  chromeWebStoreLink: 'https://chrome.google.com/webstore/detail/aabbaabbccddccddeeffeeffgggghhhi',
}

export default config
